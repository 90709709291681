<template>
    <div class="login">
        <h2>Schön, dass du hier bist!</h2>

        <div class="social-button" @click="socialLogin('facebook')">
            <img src="@/assets/ic_social_facebook.svg" alt="Facebook"/>
            <div class="social-button__label">Mit Facebook anmelden</div>
        </div>

        <div class="social-button" @click="socialLogin('google')">
            <img src="@/assets/ic_social_google.svg" alt="Google"/>
            <div class="social-button__label">Mit Google anmelden</div>
        </div>

        <div class="social-button" @click="socialLogin('apple')">
            <img src="@/assets/ic_social_apple.svg" alt="Apple"/>
            <div class="social-button__label">Mit Apple anmelden</div>
        </div>

        <!-- <div class="social-button">
            <img src="@/assets/ic_social_spielerplus.svg" alt="SpielerPlus"/>
            <div class="social-button__label">Mit SpielerPlus anmelden</div>
        </div> -->

        <div class="login__divider">oder</div>

        <v-form ref="form" v-model="valid" lazy-validation>
            <v-text-field validate-on-blur name="email" placeholder="E-Mail-Adresse" type="email"
                          v-model="email" :rules="emailRules" required>
            </v-text-field>
            <v-text-field validate-on-blur name="password" placeholder="Passwort" id="password"
                          type="password" required v-model="password" :rules="passwordRules">
            </v-text-field>
        </v-form>

        <v-btn :disabled="!valid" class="button button--primary" @click="submit">Einloggen</v-btn>

        <small><router-link to="/password-forgot">Passwort vergessen?</router-link></small>

    </div>
</template>
<script>
export default {
    name: 'Signin',
    data() {
        return {
            valid: true,
            email: '',
            password: '',
            emailRules: [
                v => !!v || 'Eine Email-Adresse ist notwendig',
                v => /.+@.+/.test(v) || 'Die Adresse ist nicht im gültigen Format.'
            ],
            passwordRules: [
                v => !!v || 'Ein Passwort ist notwendig.',
                v =>
                    v.length >= 6 ||
                    'Das Passwort muss länger als 6 Zeichen sein.'
            ]
        };
    },
    methods: {
        submit() {
            let redirectUrl = window.localStorage.getItem("_redirectAfterSignIn") || "/";
            if (this.$refs.form.validate()) {
                this.$store.dispatch('userLogin', {
                    email: this.email,
                    password: this.password,
                    redirect: redirectUrl
                });
            }
        },
        socialLogin(provider) {
            let redirectUrl = window.localStorage.getItem("_redirectAfterSignIn") || "/";
            this.$store.dispatch('socialLogin', {providerString: provider, redirect: redirectUrl});
        },
    }
};
</script>

<style lang="scss">
    .login {
        text-align: center;

        h2 {
            margin: 24px 0 32px;
        }

        .social-button {
            display: flex;
            align-items: center;
            background: $colorWhite;
            box-shadow: 0 8px 16px 0 rgba(30,29,31,0.04);
            border-radius: 8px;
            padding: 12px;
            margin-bottom: 8px;

            img {
                flex-shrink: 0;
                width: 24px;
                height: 24px;
            }

            &__label {
                font-size: 14px;
                line-height: 20px;
                font-weight: 600;
                color: $colorBlack;
                padding-right: 24px;
                text-align: center;
                width: 100%;
            }
        }

        &__divider {
            font-size: 14px;
            line-height: 20px;
            font-weight: 700;
            color: rgba($colorBlack, .24);
            text-transform: uppercase;
            margin: 19px 0;
        }

        small {
            display: flex;
            justify-content: center;
            margin: -5px 0 20px;
        }
    }
</style>